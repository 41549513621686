import React from "react";

import { ReactComponent as EnvelopeSVG } from "./envelope.svg";
import { ReactComponent as PhoneSVG } from "./phone.svg";

import styles from "./styles.module.scss";

type FooterProps = {};

const Footer: React.FunctionComponent<FooterProps> = () => {
  return (
    <footer className={styles["footer"]}>
      <div>
        <div>
          <p>
            © BPP Holdings Limited 2021 - Owned by private equity firm TDR
            <br />
            BPP House, Aldine Place, 142-144 Uxbridge Road, London W12 8AA, registered in England and Wales under
            company number 01245304
          </p>
        </div>
        <div>
          <h4>Contact</h4>
          <p>
            <a href="tel:03300603100">
              <PhoneSVG />
              03300 603 100
            </a>
            <br />
            <small>Lines open: 8am-6pm Mon-Fri</small>
          </p>
          <a href="https://www.bpp.com/request-information" rel="noopener noreferrer" target="_blank">
            <EnvelopeSVG />
            Request callback
          </a>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
