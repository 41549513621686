import React from "react";
import { BrowserRouter as ReactRouter, Switch, Route } from "react-router-dom";

import { ROUTE } from "./constants";
import RouteApp from "routes/app";

import RouteSolicitorOrBarrister from "routes/solicitor-or-barrister";
import RouteWhichSQECourse from "routes/which-sqe-course";
import RouteLPCSQE from "routes/lpc-or-sqe";

const Router: React.FunctionComponent = () => {
  return (
    <ReactRouter>
      <RouteApp>
        <Switch>
          <Route exact path={[ROUTE.LPC_OR_SQE, ROUTE.LPC_OR_SQE_RESULTS]}>
            <RouteLPCSQE />
          </Route>
          <Route exact path={[ROUTE.SOLICITOR_OR_BARRISTER, ROUTE.SOLICITOR_OR_BARRISTER_RESULTS]}>
            <RouteSolicitorOrBarrister />
          </Route>
          <Route exact path={[ROUTE.WHICE_SQE_COURSE, ROUTE.WHICE_SQE_COURSE_RESULTS]}>
            <RouteWhichSQECourse />
          </Route>
          <Route component={() => { 
            window.location.replace("https://www.bpp.com/courses/law/postgraduate");
            return null;
          }}/>
        </Switch>
      </RouteApp>
    </ReactRouter>
  );
};

export default Router;
