import React, { useState } from "react";
import { matchPath, Redirect, useHistory, useLocation } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";

import { defaultValues, Model, questions, schema } from "./model";
import { ROUTE } from "modules/router/constants";
import { shallowEqual } from "modules/utils";
import analytics from "./analytics";
import Template from "./Template";

const Route: React.FunctionComponent = () => {
  const [answers, setAnswers] = useState<Model>(defaultValues);
  const location = useLocation();
  const history = useHistory();
  const handleSubmit: SubmitHandler<Model> = (values) => {
    setAnswers(values);
    analytics(values);
    window.scrollTo(0, 0);
    history.push(ROUTE.LPC_OR_SQE_RESULTS);
  };
  return shallowEqual(answers, defaultValues) &&
    matchPath(location.pathname, { exact: true, path: ROUTE.LPC_OR_SQE_RESULTS }) ? (
    <Redirect to={ROUTE.LPC_OR_SQE} />
  ) : (
    <Template answers={answers} onSubmit={handleSubmit} questions={questions} schema={schema} />
  );
};

export default Route;
