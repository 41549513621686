import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";
import Main from "components/Main";

type TemplateProps = {};

const Template: React.FunctionComponent<TemplateProps> = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </React.Fragment>
  );
};

export default Template;
