import React from "react";

export type BodyProps = {
  children?: React.ReactNode;
};

function Body({ children }: BodyProps) {
  return <div>{children}</div>;
}
export default React.memo(Body);
