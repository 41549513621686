import React from "react";
import { DeepPartial, SubmitHandler, UnpackNestedValue } from "react-hook-form";
import { AnyObjectSchema } from "yup";

import Question, { QuestionType } from "components/Question";
import Questions from "components/Questions";
import Button from "components/Button";
import { Model } from "./model";

type ComponentProps = {
  answers: UnpackNestedValue<DeepPartial<Model>>;
  onSubmit: SubmitHandler<Model>;
  questions: Record<string, QuestionType<Model>>;
  schema?: AnyObjectSchema;
};

function Component({ answers, onSubmit, questions, schema }: ComponentProps) {
  return (
    <Questions answers={answers} onSubmit={onSubmit} schema={schema}>
      {({ control, isValid }) => (
        <React.Fragment>
          {Object.values(questions).map((question) => (
            <Question key={question.name} question={question} control={control} />
          ))}
          {Object.values(questions).length > 0 && <Button disabled={!isValid} label="Continue" />}
        </React.Fragment>
      )}
    </Questions>
  );
}

export default React.memo(Component) as typeof Component;
