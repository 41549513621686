import { useContext, useLayoutEffect, useRef, useState } from "react";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import disableScroll from "disable-scroll";
import { isIE } from "react-device-detect";

import { Context } from "components/Questions/context";

function scrollDisable() {
  const html = document.getElementsByTagName("html")[0];
  html.classList.add("scroll-disable");
  !isIE && disableScroll.on();
}

function scrollEnable() {
  const html = document.getElementsByTagName("html")[0];
  html.classList.remove("scroll-disable");
  !isIE && disableScroll.off();
}

export function useScroll() {
  const [shouldScroll, setShouldScroll] = useState(false);
  const { scrolling, setScrolling } = useContext(Context);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (!scrolling && setScrolling && shouldScroll && ref.current) {
      scrollDisable();
      setScrolling(true);
      setShouldScroll(false);
      smoothScrollIntoView(ref.current, {
        block: "center",
        duration: 500,
        ease: (t) => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2,
        inline: "start",
        scrollMode: "always",
      }).then(() => {
        scrollEnable();
        setScrolling(false);
      });
    }
  }, [scrolling, setScrolling, shouldScroll]);
  return { ref, setShouldScroll };
}
