import React from "react";

import Results from "components/Results";
import { Model } from "./model";

type TemplateProps = {
  answers: Model;
};

function Component({ answers }: TemplateProps) {
  let barrister = 0;
  let solicitor = 0;
  answers.question1 === "B" ? (solicitor += 1) : (barrister += 1);
  answers.question2 === "A" ? (solicitor += 1.5) : (barrister += 1.5);
  answers.question3 === "B" ? (solicitor += 1.5) : (barrister += 1);
  answers.question4 === "B" ? (solicitor += 1) : (barrister += 1.5);
  answers.question5 === "B" ? (solicitor += 1) : (barrister += 1.5);
  answers.question6 === "B" ? (solicitor += 1) : (barrister += 1);
  answers.question7 === "B" ? (solicitor += 1) : (barrister += 1);
  return (
    <Results>
      {solicitor >= barrister ? (
        <React.Fragment>
          <h3>Solicitor</h3>
          <p>Based on your answers, you may prefer a legal career as a solicitor.</p>
          <p>
            As a solicitor you could work for a range of organisations including big legal firms, high street law
            practices or within an organisation, and will primarily conduct legal work outside of a courtroom. You would
            be expected to have specialist knowledge in particular areas of the law and you would typically advise
            clients or your organisation and be responsible for drafting documents. There is a lot more a solicitor is
            responsible for and we suggest you research this fully.
          </p>
          <p>
            <a href="https://www.bpp.com/courses/law" rel="noopener noreferrer" target="_blank">
              Find out more about our range of solicitor training courses here.
            </a>
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3>Barrister</h3>
          <p>Based on your answers, you may prefer a legal career as a barrister.</p>
          <p>
            As a barrister your main duties would be in the courtroom representing clients - known as advocacy - and
            this would involve analysis of a case or scenario in which you prepare arguments for your client. There is a
            lot more a barrister is responsible for and we suggest you research this fully.
          </p>
          <p>
            Our training course for aspiring barristers is developed in close collaboration with members of the Bar and
            taught by experienced practitioners.{" "}
            <a
              href="https://www.bpp.com/courses/law/postgraduate/btc-barrister-training-course"
              rel="noopener noreferrer"
              target="_blank"
            >
              Find out more here.
            </a>
          </p>
        </React.Fragment>
      )}
    </Results>
  );
}

export default React.memo(Component) as typeof Component;
