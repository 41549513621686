import React from "react";

import styles from "./styles.module.scss";

type MainProps = { children?: React.ReactNode };

const Main: React.FunctionComponent<MainProps> = ({ children }) => {
  return <main className={styles["main"]}>{children}</main>;
};

export default React.memo(Main);
