import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import reportWebVitals from "./reportWebVitals";

import { isMobile } from "react-device-detect";
import "modules/theme/sass/_index.scss";
import App from "modules/app";

process.env.NODE_ENV !== "development" && TagManager.initialize({ gtmId: "GTM-P4JGTB" });

isMobile
  ? document.getElementsByTagName("html")[0].classList.add("platform-mobile")
  : document.getElementsByTagName("html")[0].classList.add("platform-desktop");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
