declare global {
  interface Window {
    dataLayer: any;
  }
}

export const trackAnswer = (quizName: string, quizQuestion: string, quizAnswer: string) => {
  if (typeof window.dataLayer === "object" && process.env.NODE_ENV !== "development") {
    window.dataLayer.push({ event: "quizQu", quizName: quizName, quizQuestion: quizQuestion, quizAnswer: quizAnswer });
  } else {
    console.log("quizQu", quizName, quizQuestion, quizAnswer);
  }
};

export const trackResults = (quizName: string, quizResult: string) => {
  if (typeof window.dataLayer === "object" && process.env.NODE_ENV !== "development") {
    window.dataLayer.push({ event: "quizRes", quizName: quizName, quizResult: quizResult });
  } else {
    console.log("quizRes", quizName, quizResult);
  }
};
