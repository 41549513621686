import { matchPath, useLocation } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import { AnyObjectSchema } from "yup";

import Questionnaire from "components/Questionnaire";
import { QuestionType } from "components/Question";
import { ROUTE } from "modules/router/constants";
import { isWebpSupported } from "modules/utils";
import Questions from "./Questions";
import { Model } from "./model";
import Results from "./Results";

type TemplateProps = {
  answers: Model;
  onSubmit: SubmitHandler<Model>;
  questions: Record<string, QuestionType<Model>>;
  schema?: AnyObjectSchema;
};

function Template({ answers, onSubmit, questions, schema }: TemplateProps) {
  const location = useLocation();
  return (
    <Questionnaire>
      <Questionnaire.Header
        background={isWebpSupported() ? "/images/header-2.webp" : "/images/header-2.jpg"}
        color="#895079"
        title="Which SQE course is for me?"
        subtitle="Find out which route we recommend for you by answering a few questions."
      />
      <Questionnaire.Body>
        {matchPath(location.pathname, { exact: true, path: ROUTE.WHICE_SQE_COURSE_RESULTS }) ? (
          <Results answers={answers} />
        ) : (
          <Questions answers={answers} onSubmit={onSubmit} questions={questions} schema={schema} />
        )}
      </Questionnaire.Body>
      <Questionnaire.Footer>
        This questionnaire may help you choose your study pathway to becoming a solicitor but it doesn't replace the
        need to do your own research. Please get in touch if you'd like to discuss your plans or future legal studies{" "}
        <a href="https://www.bpp.com/contact-bpp/request-information" rel="noopener noreferrer" target="_blank">
          here
        </a>{" "}
        or call us on <strong>03300 603 100</strong>.
      </Questionnaire.Footer>
    </Questionnaire>
  );
}

export default Template;
