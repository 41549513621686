import parse from "html-react-parser";
import DOMPurify from "dompurify";

export const shallowEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
};

export const htmlSafe = (string: string) => {
  return parse(DOMPurify.sanitize(string, { ADD_ATTR: ["target"] }));
};

let support: boolean;
export const isWebpSupported = () => {
  if (typeof support !== "undefined") return support;
  const element = typeof document === "object" ? document.createElement("canvas") : undefined;
  support = !!element && element.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  return support;
};
