import React from "react";

import Results from "components/Results";
import { Model } from "./model";

type TemplateProps = {
  answers: Model;
};

function Component({ answers }: TemplateProps) {
  return (
    <Results>
      {answers.question1 === "C" && (
        <React.Fragment>
          <h3>SQE</h3>
          <p>In order to qualify as a solicitor you must follow the SQE route.</p>
          <p>The SRA's transitional arrangements state that you are not eligible for the LPC route.</p>
          <p>
            Find out more about our new{" "}
            <a href="https://www.bpp.com/courses/law/postgraduate/sqe" rel="noopener noreferrer" target="_blank">
              SQE programmes
            </a>{" "}
            or call us on <strong>03300 603 100</strong>.
          </p>
        </React.Fragment>
      )}
      {(answers.question1 === "D" || answers.question2 === "A") && (
        <React.Fragment>
          <h3>LPC</h3>
          <p>
            You should speak to your future employer but, if they have no preference, we recommend that you progress
            onto the LPC. Our LPC has been fine-tuned over many years, in collaboration with our wide network of legal
            practices, to train you for exam success and for practice. It has a quality assurance that the firms you
            want to work with recognise and trust.{" "}
            <a
              href="https://www.bpp.com/courses/law/postgraduate/lpc-legal-practice-course"
              rel="noopener noreferrer"
              target="_blank"
            >
              Find out more
            </a>{" "}
            or call us on <strong>03300 603 100</strong>.
          </p>
        </React.Fragment>
      )}
      {answers.question3 === "A" && (
        <React.Fragment>
          <h3>SQE</h3>
          <p>
            You could qualify faster if you follow the SQE route - as you can potentially 'bank' previous work
            experience towards the Qualifying Work Experience requirement. You will still need to study for and pass the
            SQE assessments.
          </p>
          <p>
            Find out more about our new{" "}
            <a href="https://www.bpp.com/courses/law/postgraduate/sqe" rel="noopener noreferrer" target="_blank">
              SQE programmes
            </a>{" "}
            or call us on <strong>03300 603 100</strong>.
          </p>
        </React.Fragment>
      )}
      {answers.question4 === "A" && (
        <React.Fragment>
          <h3>SQE</h3>
          <p>
            The SQE gives you more flexibility in terms of when, where and how you accrue the required 2 years'
            Qualifying Work Experience, compared with the Period of Recognised Training (commonly known as the training
            contract, part of the LPC pathway). Based on what we know, many employers will prefer that you have already
            undertaken some or all of the SQE assessments before starting your Qualifying Work Experience.
          </p>
          <p>
            Find out more about our new{" "}
            <a href="https://www.bpp.com/courses/law/postgraduate/sqe" rel="noopener noreferrer" target="_blank">
              SQE programmes
            </a>{" "}
            or call us on <strong>03300 603 100</strong>.
          </p>
        </React.Fragment>
      )}
      {answers.question5 === "A" && (
        <React.Fragment>
          <h3>LPC</h3>
          <p>
            Based on your answers, you may benefit from choosing the LPC study route. Our LPC has been fine-tuned over
            many years, in collaboration with our wide network of legal practices, to train you for exam success and for
            practice. It has a quality assurance that the firms you want to work with recognise and trust.{" "}
            <a
              href="https://www.bpp.com/courses/law/postgraduate/lpc-legal-practice-course"
              rel="noopener noreferrer"
              target="_blank"
            >
              Find out more
            </a>{" "}
            or call us on <strong>03300 603 100</strong>.
          </p>
        </React.Fragment>
      )}
      {answers.question5 === "B" && (
        <React.Fragment>
          <h3>SQE</h3>
          <p>Based on your answers, you may benefit from choosing the SQE study route.</p>
          <p>
            Our SQE training will build your law knowledge, legal skills and practice skills - which is fundamental for
            career success in an increasingly demanding profession, while helping you pass the new assessments.
          </p>
          <p>
            Find out more about our new{" "}
            <a href="https://www.bpp.com/courses/law/postgraduate/sqe" rel="noopener noreferrer" target="_blank">
              SQE programmes
            </a>{" "}
            or call us on <strong>03300 603 100</strong>.
          </p>
        </React.Fragment>
      )}
    </Results>
  );
}

export default React.memo(Component) as typeof Component;
