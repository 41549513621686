import React from "react";
import { DeepPartial, SubmitHandler, UnpackNestedValue } from "react-hook-form";
import { AnyObjectSchema } from "yup";

import Question, { QuestionType } from "components/Question";
import Questions from "components/Questions";
import Button from "components/Button";
import { Model } from "./model";

type ComponentProps = {
  answers: UnpackNestedValue<DeepPartial<Model>>;
  onSubmit: SubmitHandler<Model>;
  questions: Record<string, QuestionType<Model>>;
  schema?: AnyObjectSchema;
};

function Component({ answers, onSubmit, questions, schema }: ComponentProps) {
  return (
    <Questions answers={answers} onSubmit={onSubmit} schema={schema}>
      {({ control, isValid, values }) => (
        <React.Fragment>
          <Question question={questions.question1} control={control} />
          <Question question={questions.question2} control={control} visible={["A", "B"].includes(values.question1)} />
          <Question question={questions.question3} control={control} visible={["B"].includes(values.question2)} />
          <Question question={questions.question4} control={control} visible={["B"].includes(values.question3)} />
          <Question question={questions.question5} control={control} visible={["B", "C"].includes(values.question4)} />
          <Button disabled={!isValid} label="Continue" />
        </React.Fragment>
      )}
    </Questions>
  );
}

export default React.memo(Component) as typeof Component;
