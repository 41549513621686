import React from "react";

export type FooterProps = {
  children?: React.ReactNode;
};

function Footer({ children }: FooterProps) {
  return (
    <footer>
      <small>{children}</small>
    </footer>
  );
}
export default React.memo(Footer);
