import { QuestionType } from "components/Question";
import * as yup from "yup";

export const schema = yup.object().shape({
  question1: yup.string().required(),
  question2: yup.string().when("question1", {
    is: (value: string) => ["A"].includes(value),
    then: yup.string().required(),
  }),
  question3: yup.string().when("question1", {
    is: (value: string) => ["B", "C"].includes(value),
    then: yup.string().required(),
  }),
  question4: yup.string().when("question1", {
    is: (value: string) => ["D"].includes(value),
    then: yup.string().required(),
  }),
  question5: yup.string().when("question1", {
    is: (value: string) => ["D"].includes(value),
    then: yup.string().required(),
  }),
});

export type Model = {
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
};

export const defaultValues: Model = {
  question1: "",
  question2: "",
  question3: "",
  question4: "",
  question5: "",
};

const question1: QuestionType<Model> = {
  label: "Select the one that best describes where you are in your solicitor training journey:",
  name: "question1",
  answers: [
    {
      label:
        "I have a degree that is in a subject other than Law and now want to start my solicitor training on the SQE route.",
      value: "A",
    },
    {
      label: "I have passed a law conversion course e.g. a PGDL or GDL and now want to prepare for my SQE assessments.",
      value: "B",
    },
    {
      label: "I have a degree in Law and now want to prepare for my SQE assessments.",
      value: "C",
    },
    {
      label: "I have passed my SQE1 and now want to prepare for SQE2.",
      value: "D",
    },
    {
      label:
        "I have already passed the LPC and am currently working in a legal practice (as a legal assistant, paralegal or similar) and would like to train to be a solicitor on the SQE route.",
      value: "E",
    },
    {
      label: "I don't fall into any of the above categories.",
      value: "F",
    },
  ],
};

const question2: QuestionType<Model> = {
  label: "If you are eligible, would you like to access Postgraduate government funding?",
  name: "question2",
  answers: [
    {
      label: "Yes, I need this funding to get my legal training started.",
      value: "A",
    },
    {
      label:
        "Yes, although I can finance the first part of my studies and I would prefer to use this government funding, to deepen my legal knowledge and develop my skills for practice, later in my legal training journey.",
      value: "B",
    },
    {
      label: "No/not applicable.",
      value: "C",
    },
  ],
};

const question3: QuestionType<Model> = {
  label: "If you are eligible, would you like to access government funding for your SQE training?",
  name: "question3",
  answers: [
    {
      label: "Yes",
      value: "A",
    },
    {
      label: "No",
      value: "B",
    },
  ],
};

const question4: QuestionType<Model> = {
  label: "Have you completed your two years' Qualifying Work Experience?",
  name: "question4",
  answers: [
    {
      label: "Yes",
      value: "A",
    },
    {
      label: "No",
      value: "B",
    },
  ],
};

const question5: QuestionType<Model> = {
  label:
    "Do you feel like you've developed the broader professional skills and have the specialist knowledge you need for the area you ultimately want to practise in?",
  name: "question5",
  answers: [
    {
      label: "Yes",
      value: "A",
    },
    {
      label: "No",
      value: "B",
    },
  ],
};

export const questions = { question1, question2, question3, question4, question5 };
