import React from "react";

import Content, { ContentProps } from "./Content";
import Footer, { FooterProps } from "./Footer";
import Header, { HeaderProps } from "./Header";
import Body, { BodyProps } from "./Body";

import styles from "./styles.module.scss";

type QuestionnaireProps = {
  children?: React.ReactNode;
};

type SubComponents = {
  Header: HeaderProps;
  Body: BodyProps;
  Content: ContentProps;
  Footer: FooterProps;
};

function Questionnaire({ children }: QuestionnaireProps) {
  return <section className={styles["questionnaire"]}>{children}</section>;
}

Questionnaire.Header = Header;
Questionnaire.Body = Body;
Questionnaire.Content = Content;
Questionnaire.Footer = Footer;

export default Questionnaire as typeof Questionnaire & SubComponents;
