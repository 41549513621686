import { QuestionType } from "components/Question";
import * as yup from "yup";

export const schema = yup.object().shape({
  question1: yup.string().required(),
  question2: yup.string().when("question1", {
    is: (value: string) => ["A", "B"].includes(value),
    then: yup.string().required(),
  }),
  question3: yup.string().when("question2", {
    is: (value: string) => ["B"].includes(value),
    then: yup.string().required(),
  }),
  question4: yup.string().when("question3", {
    is: (value: string) => ["B"].includes(value),
    then: yup.string().required(),
  }),
  question5: yup.string().when("question4", {
    is: (value: string) => ["B", "C"].includes(value),
    then: yup.string().required(),
  }),
});

export type Model = {
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
};

export const defaultValues: Model = {
  question1: "",
  question2: "",
  question3: "",
  question4: "",
  question5: "",
};

const question1: QuestionType<Model> = {
  label: "On 31st August 2021, where were you in your study journey?",
  name: "question1",
  answers: [
    {
      label: "I had completed or was part way through a law degree or law conversion course",
      value: "A",
    },
    {
      label:
        "I was a non-law graduate enrolled on a law conversion course that commences <strong>before 31st December 2021</strong>",
      value: "B",
    },
    {
      label:
        "I was a non-law graduate enrolled on a law conversion course that commences <strong>after 31st December 2021</strong>",
      value: "C",
    },
    {
      label:
        "I had accepted a training contract offer and was a non-law graduate, enrolled on a law conversion course that commences <strong>before 31st December 2021</strong>",
      value: "D",
    },
  ],
};

const question2: QuestionType<Model> = {
  label: "Have you already secured a training contract?",
  name: "question2",
  answers: [
    {
      label: "Yes",
      value: "A",
    },
    {
      label: "No",
      value: "B",
    },
  ],
};

const question3: QuestionType<Model> = {
  label:
    "Do you already have significant experience working in a law firm, in-house legal team or other environment providing legal services that could count towards the two years' Qualifying Work Experience required for the SQE?",
  name: "question3",
  answers: [
    {
      label: "Yes",
      value: "A",
    },
    {
      label: "No",
      value: "B",
    },
  ],
};

const question4: QuestionType<Model> = {
  label: "How would you like to complete your Qualifying Work Experience?",
  name: "question4",
  answers: [
    {
      label: "With multiple employers",
      value: "A",
    },
    {
      label: "With a single employer",
      value: "B",
    },
    {
      label: "I don't mind",
      value: "C",
    },
  ],
};

const question5: QuestionType<Model> = {
  label: "Do you have a preferred type of assessment?",
  name: "question5",
  answers: [
    {
      label: "I prefer to give written answers to questions in an 'open book' situation.",
      value: "A",
    },
    {
      label: "I prefer multiple choice style assessments",
      value: "B",
    },
  ],
};

export const questions = { question1, question2, question3, question4, question5 };
