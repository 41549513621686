import { QuestionType } from "components/Question";
import * as yup from "yup";

export const schema = yup.object().shape({
  question1: yup.string().required(),
  question2: yup.string().required(),
  question3: yup.string().required(),
  question4: yup.string().required(),
  question5: yup.string().required(),
  question6: yup.string().required(),
  question7: yup.string().required(),
});

export type Model = {
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
};

export const defaultValues: Model = {
  question1: "",
  question2: "",
  question3: "",
  question4: "",
  question5: "",
  question6: "",
  question7: "",
};

const question1: QuestionType<Model> = {
  label: "Do you prefer to work independently or as part of team?",
  name: "question1",
  answers: [
    {
      label: "I prefer to work independently, working with others where necessary.",
      value: "A",
    },
    {
      label: "While happy to work independently, I prefer working as part of a team, managing and collating input from different colleagues.",
      value: "B",
    },
  ],
};

const question2: QuestionType<Model> = {
  label: "How important is it to have financial security and a structured career path?",
  name: "question2",
  answers: [
    {
      label: "I want to have the regular income and structured career path that comes with being an employee.",
      value: "A",
    },
    {
      label:
        "I am comfortable being self employed and finding work on a less consistent basis, knowing that the potential financial reward is very high.",
      value: "B",
    },
  ],
};

const question3: QuestionType<Model> = {
  label: "Do you want your job to involve regular client contact?",
  name: "question3",
  answers: [
    {
      label:
        "I am happy not to have regular client contact, doing the task well is more rewarding to me than working directly with clients.",
      value: "A",
    },
    {
      label:
        "I prefer to have a career that involves regular client contact, supporting clients from start to finish in their legal matter.",
      value: "B",
    },
  ],
};

const question4: QuestionType<Model> = {
  label: "Which of the following describes you best?",
  name: "question4",
  answers: [
    {
      label: "Theatrical: a natural performer.",
      value: "A",
    },
    {
      label: "Methodical and organised.",
      value: "B",
    },
  ],
};

const question5: QuestionType<Model> = {
  label: "How much do you enjoy public speaking and presenting?",
  name: "question5",
  answers: [
    {
      label: "I really like public speaking and presenting and want this to be a key part of my job.",
      value: "A",
    },
    {
      label: "I prefer analysing, managing and planning, and not necessarily being involved in public speaking.",
      value: "B",
    },
  ],
};

const question6: QuestionType<Model> = {
  label: "Choose the one that gives you more satisfaction:",
  name: "question6",
  answers: [
    {
      label: "Completing a discrete piece of work and then moving on to the next.",
      value: "A",
    },
    {
      label: "Planning and managing a project from start to finish.",
      value: "B",
    },
  ],
};

const question7: QuestionType<Model> = {
  label: "Do you like the idea of being self-employed and in charge of your own time?",
  name: "question7",
  answers: [
    {
      label: "I like the idea of being self-employed and the freedom and flexibility this would give me.",
      value: "A",
    },
    {
      label: "This isn't a big driver for me: with freedom comes uncertainty and I'm not sure this is for me.",
      value: "B",
    },
  ],
};

export const questions = { question1, question2, question3, question4, question5, question6, question7 };
