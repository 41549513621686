import React from "react";

import styles from "./styles.module.scss";

type ResultsProps = { children?: React.ReactNode };

const Results: React.FunctionComponent<ResultsProps> = ({ children }) => {
  return <section className={styles["results"]}>{children}</section>;
};

export default React.memo(Results);
