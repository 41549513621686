import React from "react";

export type HeaderProps = {
  background: string;
  color: string;
  subtitle: string;
  title: string;
};

function Header({ background, color, subtitle, title }: HeaderProps) {
  const style = {
    backgroundImage: `url(${background})`,
    backgroundColor: color,
  };
  return (
    <header style={style}>
      <div>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </header>
  );
}
export default React.memo(Header);
