export enum ROUTE {
  LPC_OR_SQE = "/lpc-or-sqe",
  LPC_OR_SQE_RESULTS = "/lpc-or-sqe/results",
  SOLICITOR_OR_BARRISTER = "/solicitor-or-barrister",
  SOLICITOR_OR_BARRISTER_RESULTS = "/solicitor-or-barrister/results",
  WHICE_SQE_COURSE = "/which-sqe-course",
  WHICE_SQE_COURSE_RESULTS = "/which-sqe-course/results",
  PAGE_NOT_FOUND = "/page-not-found",
}

type Routes = {
  path: ROUTE;
  title: string;
}[];

export const routes: Routes = [
  {
    path: ROUTE.LPC_OR_SQE,
    title: "Should I do the LPC or the SQE?",
  },
  {
    path: ROUTE.LPC_OR_SQE_RESULTS,
    title: "Should I do the LPC or the SQE?",
  },
  {
    path: ROUTE.SOLICITOR_OR_BARRISTER,
    title: "Should I be a solicitor or a barrister?",
  },
  {
    path: ROUTE.SOLICITOR_OR_BARRISTER_RESULTS,
    title: "Should I be a solicitor or a barrister?",
  },
  {
    path: ROUTE.WHICE_SQE_COURSE,
    title: "Which SQE course is for me?",
  },
  {
    path: ROUTE.WHICE_SQE_COURSE_RESULTS,
    title: "Which SQE course is for me?",
  },
  {
    path: ROUTE.PAGE_NOT_FOUND,
    title: "Page Not Found",
  },
];
