import React from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

type ButtonProps = {
  disabled?: boolean;
  label: string;
};

const Button: React.FunctionComponent<ButtonProps> = ({ disabled, label }) => {
  const className = clsx(styles["button"], disabled && styles["disabled"]);
  return <button className={className}>{label}</button>;
};

export default React.memo(Button);
