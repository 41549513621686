import React from "react";

import Results from "components/Results";
import { Model } from "./model";

type TemplateProps = {
  answers: Model;
};

function Component({ answers }: TemplateProps) {
  return (
    <Results>
      {answers.question2 === "A" && (
        <React.Fragment>
          <h3>SQE Training Package for Non-Law Graduates</h3>
          <p>
            As you are new to studying law, we recommend our{" "}
            <a
              href="https://www.bpp.com/courses/law/postgraduate/sqe/non-law-graduate"
              rel="noopener noreferrer"
              target="_blank"
            >
              <strong>SQE Training Package for Non-Law Graduates</strong>
            </a>
            .
          </p>
          <p>
            This course comprises our Law Conversion course (PGDL) and our SQE Preparation course. It will initially
            build your core foundational legal knowledge, before developing the knowledge of the specific legal practice
            areas that will be assessed in the first part of the Solicitors Qualifying Exam (SQE1). It will also start
            to develop the portfolio of skills that are assessed in SQE2, giving you a head start and the confidence to
            impress prospective employers.
          </p>
          <p>This package is eligible for Postgraduate funding of up to £11,570.</p>
          <p>
            <strong>Find out more - call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
      {answers.question2 === "B" && (
        <React.Fragment>
          <h3>Law Conversion Course (PGDL) & SQE Training Package for Law Graduates (Master's version)</h3>
          <p>
            As you are new to studying law, we recommend you first complete our{" "}
            <strong>
              <a
                href="http://bpp.com/courses/law/postgraduate/pgdl-law-conversion-course"
                rel="noopener noreferrer"
                target="_blank"
              >
                Law Conversion Course (PGDL)
              </a>
            </strong>
            , which will build the core foundational legal knowledge you need for future success in the assessments,
            then move onto our{" "}
            <strong>
              <a
                href="http://bpp.com/courses/law/postgraduate/sqe/law-graduate"
                rel="noopener noreferrer"
                target="_blank"
              >
                SQE Training Package for Law Graduates (Master's version)
              </a>
            </strong>
            .
          </p>
          <p>
            Designed in collaboration with several prestigious law firms, this comprehensive, commercially focused, SQE
            Training Package for Law Graduates has been put together specifically for graduates (with the core
            foundational legal knowledge gained from a law degree or law conversion course) who want to gain a strong
            competitive edge in a challenging employment market.
          </p>
          <p>
            Comprising our SQE Preparation course, Essentials for Practice course, an additional research module (where
            you'll produce a professional asset and submit a reflective statement on your learning and development
            throughout the project) and an optional SQE2 Coaching course, this programme initially focuses on developing
            the legal knowledge and practical skills needed for success in the SQE assessments, before building the
            specialist legal knowledge and wider professional skills that employers seek.
          </p>
          <p>This package is eligible for Postgraduate funding of up to £11,570.</p>
          <p>
            <strong>Find out more - call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
      {answers.question2 === "C" && (
        <React.Fragment>
          <h3>Law Conversion Course (PGDL)</h3>
          <p>
            As you are new to studying law, we recommend you first complete our{" "}
            <strong>
              <a
                href="http://bpp.com/courses/law/postgraduate/pgdl-law-conversion-course"
                rel="noopener noreferrer"
                target="_blank"
              >
                Law Conversion Course (PGDL)
              </a>
            </strong>
            , which will build the core foundational legal knowledge you need for future success in the assessments,
            then move onto our{" "}
            <strong>
              <a
                href="http://bpp.com/courses/law/postgraduate/sqe/law-graduate"
                rel="noopener noreferrer"
                target="_blank"
              >
                SQE Training Package for Law Graduates
              </a>
            </strong>
            .
          </p>
          <p>
            Designed in collaboration with a several prestigious law firms, this comprehensive, commercially focused
            programme has been put together specifically for graduates (with the core foundational legal knowledge
            gained from a law degree or law conversion course) who want to gain a strong competitive edge in a
            challenging employment market.
          </p>
          <p>
            Comprising our SQE Preparation course, Essentials for Practice course and an optional SQE2 Coaching course,
            this programme initially focuses on developing the legal knowledge and practical skills needed for success
            in the SQE assessments, before building the specialist legal knowledge and wider professional skills that
            employers seek.
          </p>
          <p>This package is eligible for Postgraduate funding of up to £11,570.</p>
          <p>
            <strong>Find out more - call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
      {answers.question3 === "A" && (
        <React.Fragment>
          <h3>SQE Training Package for Law Graduates – Master's Version</h3>
          <p>
            <strong>
              We recommend our{" "}
              <a
                href="http://bpp.com/courses/law/postgraduate/sqe/law-graduate"
                rel="noopener noreferrer"
                target="_blank"
              >
                SQE Training Package for Law Graduates – Master's version
              </a>
              .
            </strong>
          </p>
          <p>
            Designed in collaboration with several prestigious law firms, this comprehensive, commercially focused,
            package of courses has been put together specifically for graduates (with the core foundational legal
            knowledge gained from a law degree or law conversion course) who want to gain a strong competitive edge in a
            challenging employment market.
          </p>
          <p>
            Comprising our SQE Preparation course, Essentials for Practice course, an additional research module (where
            you'll produce a professional asset and submit a reflective statement on your learning and development
            throughout the project) and an optional SQE2 Coaching course, this programme initially focuses on developing
            the legal knowledge and practical skills needed for success in the SQE assessments, before building the
            specialist legal knowledge and wider professional skills that employers seek.
          </p>
          <p>This package is eligible for Postgraduate funding of up to £11,570.</p>
          <p>
            <strong>Find out more - call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
      {answers.question3 === "B" && (
        <React.Fragment>
          <h3>SQE Training Package for Law Graduates</h3>
          <p>
            <strong>
              We recommend our{" "}
              <a
                href="http://bpp.com/courses/law/postgraduate/sqe/law-graduate"
                rel="noopener noreferrer"
                target="_blank"
              >
                SQE Training Package for Law Graduates
              </a>
              .
            </strong>
          </p>
          <p>
            Designed in collaboration with several prestigious law firms, this comprehensive, commercially focused,
            package of courses has been put together specifically for graduates (with the core foundational legal
            knowledge gained from a law degree or law conversion course) who want to gain a strong competitive edge in a
            challenging employment market.
          </p>
          <p>
            Comprising our SQE Preparation course, Essentials for Practice course and an optional SQE2 Coaching course,
            this programme initially focuses on developing the legal knowledge and practical skills needed for success
            in the SQE assessments, before building the specialist legal knowledge and wider professional skills that
            employers seek.
          </p>
          <p>This package is eligible for Postgraduate funding of up to £11,570.</p>
          <p>
            <strong>Find out more - call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
      {(answers.question1 === "E" ||
        ((answers.question4 === "A" || answers.question4 === "B") && answers.question5 === "A")) && (
        <React.Fragment>
          <h3>SQE2 Coaching Course</h3>
          <p>
            <strong>
              We recommend our{" "}
              <a
                href="http://bpp.com/courses/law/postgraduate/sqe/sqe-coaching"
                rel="noopener noreferrer"
                target="_blank"
              >
                SQE2 Coaching course
              </a>
              .
            </strong>
          </p>
          <p>
            When you are almost ready to take your SQE2 assessment, our short, intensive SQE2 Coaching course will help
            you prepare for the crucial SQE2 assessment, with personalised feedback allowing you to pinpoint the
            specific areas you need to improve. Helping you to familiarise with the assessment format, this course
            includes online SQE2 practice workbooks with examples, as well as the opportunity for plenty of practice
            assessments that will help you feel more confident and ready for the final SQE2 stage.
          </p>
          <p>
            <strong>Find out more - call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
      {(answers.question4 === "A" || answers.question4 === "B") && answers.question5 === "B" && (
        <React.Fragment>
          <h3>Essentials for Practice & SQE2 Coaching Course</h3>
          <p>
            <strong>
              We recommend our{" "}
              <a
                href="http://bpp.com/courses/law/postgraduate/sqe/essentials-for-practice"
                rel="noopener noreferrer"
                target="_blank"
              >
                Essentials for Practice course
              </a>{" "}
              and our{" "}
              <a
                href="http://bpp.com/courses/law/postgraduate/sqe/sqe-coaching"
                rel="noopener noreferrer"
                target="_blank"
              >
                SQE2 Coaching course
              </a>
              .
            </strong>
          </p>
          <p>
            Designed in close collaboration with several prestigious law firms, our{" "}
            <strong>Essentials for Practice course</strong> is uniquely focused on building your legal knowledge in the
            specialist areas of law and practice that employers are looking for. It also develops those key professional
            skills that will enhance your employability, providing you with a strong competitive edge in a challenging
            employment market, whilst continuing to develop the core legal skills which are needed for success in
            practice.
          </p>
          <p>
            When you are almost ready to take your SQE2 assessment, our short, intensive{" "}
            <strong>SQE2 Coaching course</strong> will help you prepare for the crucial SQE2 assessment, with
            personalised feedback allowing you to pinpoint the specific areas you need to improve. Helping you to
            familiarise with the assessment format, this course includes online SQE2 practice workbooks with examples,
            as well as the opportunity for plenty of practice assessments that will help you feel more confident and
            ready for the final SQE2 stage.
          </p>
          <p>
            <strong>Find out more - call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
      {answers.question1 === "F" && (
        <React.Fragment>
          <p>
            One of our advisors will be happy to take the time to understand your specific situation and recommend a
            training pathway that best suits your career goals.
          </p>
          <p>
            <strong>Please call us on 03300 603 100.</strong>
          </p>
        </React.Fragment>
      )}
    </Results>
  );
}

export default React.memo(Component) as typeof Component;
