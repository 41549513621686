import React from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as LogoSVG } from "./logo.svg";
import { ROUTE } from "modules/router/constants";
import styles from "./styles.module.scss";

type HeaderProps = {};

const Header: React.FunctionComponent<HeaderProps> = () => {
  return (
    <header className={styles["header"]}>
      <div>
        <div>
          <a href="https://www.bpp.com/contact-bpp" rel="noreferrer" target="_blank">
            Contact us
          </a>
        </div>
      </div>
      <div>
        <div>
          <LogoSVG />
          {process.env.NODE_ENV === "development" && (
            <nav>
              <NavLink exact to={ROUTE.LPC_OR_SQE}>
                LPC or SQE?
              </NavLink>
              <NavLink exact to={ROUTE.SOLICITOR_OR_BARRISTER}>
                Solicitor or Barrister
              </NavLink>
              <NavLink exact to={ROUTE.WHICE_SQE_COURSE}>
                Which SQE course?
              </NavLink>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
