import { trackAnswer, trackResults } from "modules/analytics";
import { QuestionType } from "components/Question";
import { Model, questions } from "./model";

const title = "Which SQE course is for me?";

const getResult = (answers: Model) => {
  if (answers.question2 === "A") return "SQE Training Package for Non-Law Graduates";
  if (answers.question2 === "B")
    return "Law Conversion Course (PGDL) & SQE Training Package for Law Graduates (Master's version)";
  if (answers.question2 === "C") return "Law Conversion Course (PGDL)";
  if (answers.question3 === "A") return "SQE Training Package for Law Graduates – Master's Version";
  if (answers.question3 === "B") return "SQE Training Package for Law Graduates";
  if (
    answers.question1 === "E" ||
    ((answers.question4 === "A" || answers.question4 === "B") && answers.question5 === "A")
  )
    return "SQE2 Coaching Course";
  if ((answers.question4 === "A" || answers.question4 === "B") && answers.question5 === "B")
    return "Essentials for Practice & SQE2 Coaching Course";
  if (answers.question1 === "F")
    return "One of our advisors will be happy to take the time to understand your specific situation and recommend a training pathway that best suits your career goals.";
  return "";
};

const getAnswer = (question: QuestionType<Model>, answer: string) => {
  return question.answers.find((item) => item.value === answer)?.label || "";
};

const analytics = (answers: Model) => {
  !!answers.question1 &&
    trackAnswer(title, questions.question1.label, getAnswer(questions.question1, answers.question1));

  !!answers.question2 &&
    trackAnswer(title, questions.question2.label, getAnswer(questions.question2, answers.question2));

  !!answers.question3 &&
    trackAnswer(title, questions.question3.label, getAnswer(questions.question3, answers.question3));

  !!answers.question4 &&
    trackAnswer(title, questions.question4.label, getAnswer(questions.question4, answers.question4));

  !!answers.question5 &&
    trackAnswer(title, questions.question5.label, getAnswer(questions.question5, answers.question5));

  trackResults(title, getResult(answers));
};

export default analytics;
