import React from "react";

import styles from "./styles.module.scss";

export type ContentProps = {
  children?: React.ReactNode;
};

function Content({ children }: ContentProps) {
  return <div className={styles["content"]}>{children}</div>;
}
export default React.memo(Content);
