import React, { createContext, useState } from "react";

type ContextType = {
  scrolling: boolean;
  setScrolling?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Context = createContext<ContextType>({
  scrolling: false,
  setScrolling: undefined,
});

const ContextProvider: React.FunctionComponent = ({ children }) => {
  const [scrolling, setScrolling] = useState<boolean>(false);
  const context = { scrolling: scrolling, setScrolling: setScrolling };
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default ContextProvider;
