import React, { useEffect, useState } from "react";
import { Control, ControllerRenderProps, Path, useController } from "react-hook-form";
import clsx from "clsx";

import { htmlSafe } from "modules/utils";
import { useScroll } from "./hooks";

import styles from "./styles.module.scss";

export type QuestionType<T> = {
  answers: { label: string; value: string }[];
  label: string;
  name: Path<T>;
};

export type QuestionProps<T> = {
  control: Control<T>;
  question: QuestionType<T>;
  visible?: boolean;
};

type AnswerProps<T> = {
  answer: any;
  field: ControllerRenderProps<T, Path<T>>;
  index: number;
};

function Answer<T>({ answer, field: { name, onBlur, onChange, ref, value }, index }: AnswerProps<T>) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === "Enter") {
      onChange(value === answer.value ? "" : answer.value);
      event.stopPropagation();
    }
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLLabelElement>) => {
    "activeElement" in document && (document.activeElement as HTMLElement).blur();
    event.preventDefault();
  };

  const handleHover = (event: React.MouseEvent<HTMLLabelElement>) => {
    //"activeElement" in document && (document.activeElement as HTMLElement).blur();
    // !!event.currentTarget.parentElement?.parentElement?.contains(document.activeElement) &&
    //   (document.activeElement as HTMLElement).blur();
  };

  const className = clsx(styles["answer"], value === answer.value && styles["selected"]);
  return (
    <div className={className}>
      <label
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleHover}
        ref={value === answer.value ? ref : index === 0 ? ref : undefined}
        tabIndex={0}
      >
        <span>{htmlSafe(answer.label)}</span>
        <input
          type="radio"
          checked={value === answer.value}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          tabIndex={0}
          value={answer.value}
        />
        <i />
      </label>
    </div>
  );
}

function Component<T>({ control, question: { answers, label, name }, visible = true }: QuestionProps<T>) {
  const {
    field: { onChange, value, ...field },
    fieldState: { invalid },
  } = useController({
    control,
    name: name,
  });
  const { ref, setShouldScroll } = useScroll();
  const [secondary] = useState(!visible);
  useEffect(() => {
    !visible && value !== "" && onChange("");
    return () => {};
  }, [onChange, value, visible]);
  useEffect(() => {
    secondary && visible && setShouldScroll(true);
  }, [secondary, setShouldScroll, visible]);
  const className = clsx(styles["question"], invalid && styles["invalid"]);
  return visible ? (
    <div className={className} ref={ref}>
      <h3>{label}</h3>
      {answers.map((answer, index) => (
        <Answer<T> key={`${name}-${index}`} answer={answer} field={{ onChange, value, ...field }} index={index} />
      ))}
      {invalid && <small>Please answer this question.</small>}
    </div>
  ) : null;
}

export default React.memo(Component) as typeof Component;
