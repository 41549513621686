import React from "react";
import { Control, DeepPartial, SubmitHandler, useForm, UnpackNestedValue } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnyObjectSchema } from "yup";

import ContextProvider from "./context";

type QuestionsProps<T> = {
  answers: UnpackNestedValue<DeepPartial<T>>;
  children?: (props: { control: Control<T>; isValid: boolean; values: UnpackNestedValue<T> }) => React.ReactNode;
  onSubmit: SubmitHandler<T>;
  schema?: AnyObjectSchema;
};

function Questions<T>({ answers, children, onSubmit, schema }: QuestionsProps<T>) {
  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm<T>({
    criteriaMode: "firstError",
    defaultValues: answers,
    mode: "onTouched",
    resolver: schema ? yupResolver(schema) : undefined,
    reValidateMode: "onChange",
    shouldFocusError: true,
  });
  const values = watch();
  return (
    <ContextProvider>
      <form onSubmit={handleSubmit(onSubmit)}>{children && children({ control, isValid, values })}</form>
    </ContextProvider>
  );
}

export default React.memo(Questions) as typeof Questions;
