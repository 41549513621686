import { trackAnswer, trackResults } from "modules/analytics";
import { QuestionType } from "components/Question";
import { Model, questions } from "./model";

const title = "Should I do the LPC or the SQE?";

const getResult = (answers: Model) => {
  if (answers.question1 === "C") return "SQE";
  if (answers.question1 === "D" || answers.question2 === "A") return "LPC";
  if (answers.question3 === "A") return "SQE";
  if (answers.question4 === "A") return "SQE";
  if (answers.question5 === "A") return "LPC";
  if (answers.question5 === "B") return "SQE";
  return "";
};

const getAnswer = (question: QuestionType<Model>, answer: string) => {
  return question.answers.find((item) => item.value === answer)?.label || "";
};

const analytics = (answers: Model) => {
  !!answers.question1 &&
    trackAnswer(title, questions.question1.label, getAnswer(questions.question1, answers.question1));

  !!answers.question2 &&
    trackAnswer(title, questions.question2.label, getAnswer(questions.question2, answers.question2));

  !!answers.question3 &&
    trackAnswer(title, questions.question3.label, getAnswer(questions.question3, answers.question3));

  !!answers.question4 &&
    trackAnswer(title, questions.question4.label, getAnswer(questions.question4, answers.question4));

  !!answers.question5 &&
    trackAnswer(title, questions.question5.label, getAnswer(questions.question5, answers.question5));

  trackResults(title, getResult(answers));
};

export default analytics;
